import { PanelPlugin } from '@grafana/data';
import { OrderSelectionOptions } from './types';
import { OrderSelectionPanel } from './OrderSelectionPanel';

export const plugin = new PanelPlugin<OrderSelectionOptions>(OrderSelectionPanel).setPanelOptions(builder => {
    return builder
        .addTextInput({
            path: 'publicApiName',
            name: 'Public API Name'
        })
        .addTextInput({
            name: 'Machine Variable',
            description:"Dashboard variable containing machine name, for example 'sensor'",
            path: 'machineVarName',
        })
        .addBooleanSwitch({
            name: 'Read-only mode',
            path: 'readOnlyMode'
        })
        .addBooleanSwitch({
            name: 'Clear button',
            path: 'clearButton'
        })
        .addBooleanSwitch({
            name: 'Machine select',
            path: 'machineSelect'
        })

        //labels
        .addTextInput({
            category: ['Labels'],
            path: 'machineTxt',
            name: 'Machine text',
            defaultValue: 'Machine'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'operatorTxt',
            name: 'Operator text',
            defaultValue: 'Operator'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'workOrderTxt',
            name: 'Work order text',
            defaultValue: 'Work Order'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'partTxt',
            name: 'Part text',
            defaultValue: 'Part'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'operationTxt',
            name: 'Operation text',
            defaultValue: 'Operation'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'startTxt',
            name: 'Start button text',
            defaultValue: 'Start'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'stopTxt',
            name: 'Stop button text',
            defaultValue: 'Stop'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'clearTxt',
            name: 'Clear button text',
            defaultValue: 'Clear'
        })
        .addTextInput({
            category: ['Labels'],
            path: 'noActiveOrderTxt',
            name: 'No active order text',
            defaultValue: 'NO ACTIVE ORDER'
        })
})

