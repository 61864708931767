import React, { PureComponent } from 'react';
import { PanelProps } from '@grafana/data';
import { Select, Button } from '@grafana/ui';
import { OrderSelectionOptions } from 'types';
import axios from 'axios';
import { getDataSourceSrv as getDataSourceService, loadPluginCss } from '@grafana/runtime';

interface Props extends PanelProps<OrderSelectionOptions> { }

export class OrderSelectionPanel extends PureComponent<Props> {
  emptyContext = {
    activeFrom: undefined,
    activeTo: undefined,
    calendar: undefined,
    calendarID: undefined,
    changedDate: undefined,
    contextID: undefined,
    createdDate: undefined,
    operation: undefined,
    operationName: undefined,
    operator: undefined,
    operatorID: undefined,
    orgItemID: undefined,
    part: undefined,
    partName: undefined,
    sensorID: undefined,
    workOrder: undefined,
    workOrderID: undefined,
    partID: undefined,
    operationID: undefined,
  };

  state = {
    selectedOperator: {
      label: undefined,
      value: undefined,
    },
    operatorID: undefined,
    selectedMachine: {
      label: undefined,
      value: undefined,
    },
    selectedWO: {
      label: undefined,
      value: undefined,
    },
    selectedPart: {
      label: undefined,
      value: undefined,
    },
    selectedOp: {
      label: undefined,
      value: undefined,
    },
    proxyUrl: '',
    currentContext: this.emptyContext,
    currentContextResponse: 'init',
    getPartsResponse: 'init',
    getOperationsResponse: 'init',
    cssLoaded: false,
  };

  proxyUrl: string | undefined;
  operatorsList: any | undefined;
  machinesList: any | undefined;
  workOrdersList: any | undefined;
  partsList: any | undefined;
  operationsList: any | undefined;
  machineFromVar: string | undefined;

  activeBorderClass = 'ord-sel-active-border';
  isIdle = false;
  inputValues = '';
  barcodeCSV: string[] = [];

  componentDidMount() {
    this.getProxyURL();    
  }

  render() {
    if (!this.state.proxyUrl) {
      return (<div />);
    }

    loadPluginCss({
      dark: 'plugins/order-selection/css/orderselection_base.css',
      light: 'plugins/order-selection/css/orderselection_base.css',
    }).then(() => this.setState({ cssLoaded: true }));

    this.setMachineFromVar();
    this.refreshContext();
    this.isIdle = true;

    return (
      <div className={this.activeBorderClass + ' ord-sel-focus-div'} onKeyDown={e => this.onKeyPressed(e)} tabIndex={0}>
        {this.renderMachinesSelect()}
        {this.renderOperatorSelect()}
        {this.renderWOSelect()}
        {this.renderPartsSelect()}
        {this.renderOperationsSelect()}
        {this.renderExisitingContextBtn()}
        {this.renderSubmitButton()}
        {this.renderClearButton()}
        {this.renderExisitingContextStatus()}
      </div>
    );
  }

  onKeyPressed = (e: any) => {
    if (this.inputValues.length > 0) {
      e.preventDefault();
    }
    if (e.key !== 'Enter') {
      this.inputValues += e.key;
    }
    setTimeout(() => {
      if (this.inputValues) {
        console.log(this.inputValues);
        this.checkForBarcode(this.inputValues);
      }
      this.inputValues = '';
    }, 200);
  };

  checkForBarcode(inputValues: string) {
    if (this.props.options.readOnlyMode || this.state.currentContext.contextID !== undefined) {
      return;
    }
    this.barcodeCSV = inputValues.split(',');
    if (this.barcodeCSV.length === 3) {
      this.setOrderFromBarcode();
    }
  }

  //#region Context methods
  refreshContext() {
    if (!this.isIdle || this.state.selectedMachine === undefined || this.state.selectedMachine.value === undefined || this.state.currentContextResponse === 'loading') {
      return;
    }
    if (
      this.state.currentContext.contextID === undefined &&
      (this.state.selectedWO.value !== undefined ||
        this.state.selectedPart.value !== undefined ||
        this.state.selectedOp.value !== undefined ||
        this.state.selectedOperator.value !== undefined)
    ) {
      //the user has made a selection, do not refresh.
      return;
    }
    this.isIdle = false;
    console.log('refreshContext for ' + this.state.selectedMachine.label);
    this.getContext((this.state.selectedMachine.value as unknown) as number);
  }
  getContext(machineId: number) {
    console.log('Checking context');
    this.isIdle = false;

    setTimeout(() => {
      let sqlContext = this.getContextFromQuery();

      let contextData;
      if (sqlContext === undefined) {
        contextData = this.emptyContext;
        this.activeBorderClass = 'ord-sel-active-border bad';
        this.clear(true);
      } else {
        contextData = sqlContext;
        this.activeBorderClass = 'ord-sel-active-border good';
      }
      this.isIdle = false;
      this.setState({
        currentContextResponse: '200',
        currentContext: contextData,
        getPartsResponse: '200',
        getOperationsResponse: '200',
      });
      setTimeout(() => {
        this.setOrderFromContext();
      });   

    });
  }
  createContext() {
    this.isIdle = false;
    const URL = window.location.origin + this.state.proxyUrl + '/publicapi/api/erp/context';
    const posLabelItems: string[] = (this.state.selectedPart.label || '').split(' ');
    const data = {
      OrgName: this.props.replaceVariables("${__org.name}"),
      WorkOrder: this.state.selectedWO.label,
      Part: posLabelItems[0],
      Operation: this.state.selectedOp?.value ?? "",
      SensorID: this.state.selectedMachine?.label ?? "",
      OperatorID: this.state.selectedOperator?.value ?? "",
    };
    axios
      .post(URL, data)
      .then(response => {
        this.isIdle = false;
        this.setState({ saveContextResponse: response.status });
        this.clear(true);
        this._onSelectMachine(this.state.selectedMachine);
      })
      .catch(e => {
        this.isIdle = false;
        this.setState({
          saveContextResponse: 'error',
        });
        console.log(e);
      });
  }
  closeContext() {
    this.isIdle = false;
    const URL = window.location.origin + this.state.proxyUrl + '/publicapi/api/erp/context/close/' + this.state.currentContext.contextID;
    axios
      .get(URL)
      .then(() => {
        this.clear(true);
        this._onSelectMachine(this.state.selectedMachine);
      })
      .catch(e => {
        console.log(e);
      });
  }
  setOrderFromContext() {
    if (this.state.currentContext === this.emptyContext || this.state.currentContext.workOrderID === undefined) {
      this.clear(true);
      return;
    }
    this.isIdle = false;
    this.setState({
      selectedWO: {
        label: this.state.currentContext.workOrder,
        value: this.state.currentContext.workOrderID,
      },
      selectedPart: {
        label: this.state.currentContext.part + ' - ' + this.state.currentContext.partName,
        value: this.state.currentContext.partID,
      },
      selectedOp: {
        label: this.state.currentContext.operation + ' - ' + this.state.currentContext.operationName,
        value: this.state.currentContext.operationID,
      },
      selectedOperator: {
        label: this.state.currentContext.operator,
        value: this.state.currentContext.operatorID,
      },
    });
    if (!this.props.options.readOnlyMode) {
      this.getParts((this.state.currentContext.workOrderID as unknown) as number, false);
      this.getOperations((this.state.currentContext.partID as unknown) as number, false);
    }
  }

  setOrderFromBarcode() {
    if (this.barcodeCSV[0]) {
      const wo = this.workOrdersList.find((o: { label: any }) => o.label === this.barcodeCSV[0]);
      this._onSelectWO(wo);
      this.barcodeCSV[0] = '';
    }
  }
  //#endregion Context methods

  setMachineFromVar() {
    const { machineVarName } = this.props.options;
    const sensorID = this.props.replaceVariables('$'+ machineVarName);

    if (sensorID === null || sensorID === this.machineFromVar || this.machinesList === undefined) {
      return;
    }
    this.machineFromVar = sensorID;
    const machineItem = this.machinesList.find((m: { label: string }) => m.label === sensorID);

    this._onSelectMachine(machineItem);
  }

  //#region _onSelect methods
  _onSelectOperator(val: any) {
    this.isIdle = false;
    this.setState({
      selectedOperator: val,
      operatorID: val.value,
    });
  }

  _onSelectMachine(val: any) {
    this.isIdle = false;
    this.activeBorderClass = 'ord-sel-active-border';
    this.setState({ selectedMachine: val });

    this.getContext(val.value);
  }

  _onSelectWO(val: any) {
    this.isIdle = false;
    this.setState({
      selectedWO: val,
    });
    this.getParts(val.value);
  }

  _onSelectPart(val: any) {
    this.isIdle = false;
    this.setState({
      selectedPart: val,
    });
    this.getOperations(val.value);
  }

  _onSelectOperation(val: any) {
    this.isIdle = false;
    this.setState({ selectedOp: val });
  }
  //#endregion

  //#region Public API methods
  getParts(woId: number, clear = true) {
    if (this.props.options.readOnlyMode || woId === 0) {
      return;
    }
    const URL = window.location.origin + this.state.proxyUrl + '/publicapi/api/erp/parts/' + woId;
    this.isIdle = false;
    if (clear) {
      this.setState({
        getPartsResponse: 'loading',
        selectedPart: {
          label: undefined,
          value: undefined,
        },
        selectedOp: {
          label: undefined,
          value: undefined,
        },
      });
      this.partsList = undefined;
      this.operationsList = undefined;
    }
    axios
      .get(URL)
      .then(response => {
        let partsData = [];
        if (response.data.length === 0) {
          this.partsList = undefined;
        } else {
          partsData = response.data as any[];
        }
        this.partsList = partsData.map(p => {
          return {
            label: p.Value,
            value: p.Key,
          };
        });
        this.isIdle = false;
        this.setState({ getPartsResponse: response.status });

        if (this.barcodeCSV[1]) {
          const part = this.partsList.find((o: { label: any }) => {
            const arr = o.label.split(' ');
            return arr[0] === this.barcodeCSV[1];
          });
          this._onSelectPart(part);
          this.barcodeCSV[1] = '';
        }
      })
      .catch(e => {
        this.isIdle = false;
        this.setState({
          getPartsResponse: 'error',
        });
        console.log(e);
      });
  }

  getOperations(partId: number, clear = true) {
    if (this.props.options.readOnlyMode || partId === 0) {
      return;
    }
    const URL = window.location.origin + this.state.proxyUrl + '/publicapi/api/erp/operations/' + partId;
    this.isIdle = false;
    if (clear) {
      this.setState({
        getOperationsResponse: 'loading',
        selectedOp: {
          label: undefined,
          value: undefined,
        },
      });
      this.operationsList = undefined;
    }
    axios
      .get(URL)
      .then(response => {
        let opsData = [];
        if (response.data.length === 0) {
          this.operationsList = undefined;
        } else {
          opsData = response.data as any[];
        }
        this.operationsList = opsData.map(p => {
          return {
            label: p.Value,
            value: p.Key,
          };
        });
        this.isIdle = false;
        this.setState({ getOperationsResponse: response.status });

        if (this.barcodeCSV[2]) {
          const op = this.operationsList.find((o: { label: any }) => {
            const arr = o.label.split(' ');
            return arr[0] === this.barcodeCSV[2];
          });
          this._onSelectOperation(op);
          this.barcodeCSV[2] = '';
        }
      })
      .catch(e => {
        this.isIdle = false;
        this.setState({
          getOperationsResponse: 'error',
        });
        console.log(e);
      });
  }

  clear(skipMachine = false) {
    if (!skipMachine) {
      this.isIdle = false;
      this.setState({
        selectedMachine: {
          label: undefined,
          value: undefined,
        },
      });
      this.activeBorderClass = 'ord-sel-active-border';
    }
    this.isIdle = false;
    this.partsList = undefined;
    this.operationsList = undefined;
    this.setState({
      selectedWO: {
        label: undefined,
        value: undefined,
      },
      selectedPart: {
        label: undefined,
        value: undefined,
      },
      selectedOp: {
        label: undefined,
        value: undefined,
      },
      selectedOperator: {
        label: undefined,
        value: undefined,
      },
    });
  }

  getListFromQuery(seriesIdx: number, list: any) {
    if (list !== undefined) {
      return list;
    }
    let result;
    const td = this.props.data.series[seriesIdx];
    if (td !== undefined && td.fields.length >= 2 && td.fields[0] !== undefined && td.fields[1] !== undefined) {
      const tmpF = td.fields[1].values.toArray();
      result = td.fields[0].values.toArray().map((r: any[], i) => {
        return {
          label: tmpF[i],
          value: r,
        };
      });
    }
    return result;
  }

  getContextFromQuery() {
    const dt = this.props.data.series.find(s => s.refId === 'D');
    if (dt === undefined || dt.fields.find(e => e.name === 'ID')?.values.toArray()[0] === undefined) {
      return undefined;
    }

    return {
      activeFrom: dt.fields.find(e => e.name === 'ActiveFrom')?.values.toArray()[0],
      activeTo: undefined,
      calendar: undefined,
      calendarID: undefined,
      changedDate: undefined,
      contextID: dt.fields.find(e => e.name === 'ID')?.values.toArray()[0],
      createdDate: undefined,
      operation: dt.fields.find(e => e.name === 'WorkOrderOperation')?.values.toArray()[0],
      operationName: dt.fields.find(e => e.name === 'OperationName')?.values.toArray()[0],
      operator: dt.fields.find(e => e.name === 'OperatorName')?.values.toArray()[0],
      operatorID: dt.fields.find(e => e.name === 'OperatorExternalID')?.values.toArray()[0],
      orgItemID: undefined,
      part: dt.fields.find(e => e.name === 'WorkOrderPart')?.values.toArray()[0],
      partName: dt.fields.find(e => e.name === 'PartName')?.values.toArray()[0],
      sensorID: dt.fields.find(e => e.name === 'SensorID')?.values.toArray()[0],
      workOrder: dt.fields.find(e => e.name === 'WorkOrder')?.values.toArray()[0],
      workOrderID: dt.fields.find(e => e.name === 'WorkOrderID')?.values.toArray()[0],
      partID: dt.fields.find(e => e.name === 'PartID')?.values.toArray()[0],
      operationID: dt.fields.find(e => e.name === 'OperationID')?.values.toArray()[0],
    };
  }

  getProxyURL() {
    const { publicApiName } = this.props.options;
    if (this.proxyUrl !== undefined) {
      return;
    }
    const srv = getDataSourceService();
    srv.get(publicApiName).then(result => {
      const us = result as any;
      this.proxyUrl = '/api/datasources/' + us.id + '/resources';
      this.isIdle = false;
      this.setState({ proxyUrl: this.proxyUrl });
    });
  }
  //#endregion

  //#region Render methods
  renderOperatorSelect() {
    this.operatorsList = this.getListFromQuery(2, this.operatorsList);

    if (this.operatorsList === undefined || !this.state.cssLoaded) {
      return;
    }
    if (this.props.options.readOnlyMode || this.state.currentContext.contextID !== undefined) {
      if (this.state.currentContext.contextID === undefined) {
        return;
      }
      return (
        <div className="ord-sel-inline-box ord-sel-read-only">
          <label>{this.props.options.operatorTxt}</label>
          <h4>{this.state.selectedOperator.label}</h4>
        </div>
      );
    }
    return (
      <div className="ord-sel-inline-box">
        <label>{this.props.options.operatorTxt}</label>
        <Select
          className="ord-sel-select"
          options={this.operatorsList}
          onChange={(id: any) => this._onSelectOperator(id)}
          value={this.state.selectedOperator}
        />
      </div>
    );
  }

  renderMachinesSelect() {
    this.machinesList = this.getListFromQuery(0, this.machinesList);
    if (this.machinesList === undefined || !this.state.cssLoaded) {
      return;
    }

    if (this.props.options.readOnlyMode || !this.props.options.machineSelect) {
      return (
        <div className="ord-sel-inline-box ord-sel-read-only">
          <label>{this.props.options.machineTxt}</label>
          <h4>{this.state.selectedMachine?.label}</h4>
        </div>
      );
    }

    return (
      <div className="ord-sel-inline-box">
        <label>{this.props.options.machineTxt}</label>
        <Select
          className="ord-sel-select"
          options={this.machinesList}
          onChange={(id: any) => this._onSelectMachine(id)}
          value={this.state.selectedMachine}
        />
      </div>
    );
  }

  renderWOSelect() {
    this.workOrdersList = this.getListFromQuery(1, this.workOrdersList);
    if (this.workOrdersList === undefined || !this.state.cssLoaded) {
      return;
    }
    if (this.props.options.readOnlyMode || this.state.currentContext.contextID !== undefined) {
      if (this.state.currentContext.contextID === undefined) {
        return;
      }
      return;
      //(
      // <div className="ord-sel-inline-box ord-sel-read-only">
      //   <label>Work Order</label>
      //   <h4>{this.state.selectedWO.label}</h4>
      // </div>
      //);
    }
    return (
      <div className="ord-sel-inline-box">
        <label>{this.props.options.workOrderTxt}</label>
        <Select className="ord-sel-select" options={this.workOrdersList} onChange={(id: any) => this._onSelectWO(id)} value={this.state.selectedWO} isSearchable={true} />
      </div>
    );
  }

  renderExisitingContextStatus() {
    if (this.state.selectedMachine.value === undefined || !this.state.cssLoaded) {
      return;
    }
    if (this.state.currentContextResponse === 'error') {
      return <span>Error!</span>;
    }

    if (this.state.currentContext.contextID === undefined && this.props.options.readOnlyMode) {
      return <h2 className="ord-sel-no-ctx">{this.props.options.noActiveOrderTxt}</h2>;
    }
    return;
  }

  renderExisitingContextBtn() {
    if (
      this.state.selectedMachine.value === undefined ||
      this.state.currentContextResponse === 'error' ||
      this.state.currentContext.contextID === undefined ||
      !this.state.cssLoaded ||
      this.props.options.readOnlyMode
    ) {
      return;
    }
    return (
      <Button title="Stop" onClick={() => this.closeContext()} variant={'destructive'} className="ord-sel-btn-ro">
        {this.props.options.stopTxt}
      </Button>
    );
  }

  renderPartsSelect() {
    if (!this.state.cssLoaded) {
      return;
    }
    if (this.props.options.readOnlyMode || this.state.currentContext.contextID !== undefined) {
      if (this.state.currentContext.contextID === undefined) {
        return;
      }
      return (
        <div className="ord-sel-inline-box ord-sel-read-only">
          <label>{this.props.options.workOrderTxt} - {this.props.options.partTxt}</label>
          <h4>
            {this.state.selectedWO.label}-{this.state.selectedPart.label}
          </h4>
        </div>
      );
    }
    return (
      <div className="ord-sel-inline-box">
        <label>{this.props.options.partTxt}</label>
        <Select className="ord-sel-select" options={this.partsList} onChange={(id: any) => this._onSelectPart(id)} value={this.state.selectedPart} />
      </div>
    );
  }

  renderOperationsSelect() {
    if (!this.state.cssLoaded) {
      return;
    }
    if (this.props.options.readOnlyMode || this.state.currentContext.contextID !== undefined) {
      if (this.state.currentContext.contextID === undefined) {
        return;
      }
      return (
        <div className="ord-sel-inline-box ord-sel-read-only">
          <label>{this.props.options.operationTxt}</label>
          <h4>{this.state.selectedOp.label}</h4>
        </div>
      );
    }
    return (
      <div className="ord-sel-inline-box">
        <label>{this.props.options.operationTxt}</label>
        <Select
          className="ord-sel-select"
          options={this.operationsList}
          onChange={(id: any) => this._onSelectOperation(id)}
          value={this.state.selectedOp}
        />
      </div>
    );
  }

  renderSubmitButton() {
    if (
      this.state.selectedOp?.value === undefined ||
      this.state.selectedMachine?.value === undefined ||
      !this.state.cssLoaded ||
      this.props.options.readOnlyMode ||
      this.state.currentContext.contextID !== undefined
    ) {
      return;
    }
    return (
      <Button onClick={() => this.createContext()} title="Start" className="ord-sel-btn">
        {this.props.options.startTxt}
      </Button>
    );
  }

  renderClearButton() {
    if (
      !this.state.cssLoaded ||
      !this.props.options.clearButton ||
      this.props.options.readOnlyMode ||
      this.state.currentContext.contextID !== undefined
    ) {
      return;
    }
    return (
      <Button onClick={() => this.clear(true)} title="Clear fields" variant={'secondary'} className="ord-sel-btn">
        {this.props.options.clearTxt}
      </Button>
    );
  }

  //#endregion
}
